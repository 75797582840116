<template>
    <div class="tw-flex tw-flex-col tw-gap-8 max-lg:tw-px-[var(--padding-left)] max-lg:tw-py-[var(--padding-top)]">
        <p class="tw-text-center tw-text-green-900 max-lg:tw-text-2xl lg:tw-text-5xl xl:tw-mb-4">{{ $t('views.suppliers.suppliers') }}</p>

        <div class="justify-center tw-flex tw-flex-row tw-gap-4">
            <v-text-field
                v-model="searchText"
                placeholder="pesquisar"
                hide-details
                variant="outlined"
                color="green-900"
                @keydown.enter="onSearch"
                class="search-input tw-w-full tw-max-w-4xl">
                <template #append-inner>
                    <v-btn
                        class="tw-absolute tw-right-0 tw-mr-1 tw-max-h-none tw-bg-green-900 tw-px-10 tw-text-white max-md:tw-hidden lg:tw-h-5/6"
                        @click="onSearch"
                        >{{ $t('shared.search') }}</v-btn
                    >
                </template>
            </v-text-field>

            <div class="tw-flex tw-items-center">
                <v-btn
                    class="lg:tw-h-5/6"
                    color="green-900"
                    variant="outlined">
                    <v-icon> mdi-filter-outline </v-icon>
                    <p class="max-md:tw-hidden">{{ $t('views.shippingCompany.shippings.filters') }}</p>
                </v-btn>
            </div>
        </div>
    </div>

    <div
        class="tw-relative tw-grid tw-grid-cols-2 tw-gap-5 tw-pb-[var(--padding-bottom)] tw-pl-[var(--padding-left)] tw-pr-[var(--padding-right)] tw-pt-[var(--padding-top)] md:tw-grid-cols-4 2xl:tw-gap-x-10 2xl:tw-gap-y-20">
        <CardSupplier
            v-for="supplier in suppliers"
            :key="supplier.id"
            :supplier="supplier" />
        <v-overlay
            :model-value="isLoading"
            contained
            absolute
            :close-on-back="false"
            :close-on-content-click="false"
            :persistent="true"
            class="tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { computed } from 'vue';
    import { watch } from 'vue';
    import CardSupplier from './CardSupplier.vue';
    import { ContentType, APIResponseMany, Category } from '../../contentTypes';
    import axios from '@axios';
    import qs from 'qs';
    import type { AxiosResponse } from 'axios';
    import { useI18n } from 'vue-i18n';

    const  {locale} = useI18n();

    const searchText = ref('');
    const searchQuery = ref('');
    const categories = ref<Category[]>([]);

    function onSearch() {
        searchQuery.value = searchText.value;
        getSuppliers();
    }

    const suppliers = ref<ContentType<'Supplier'>[]>([]);
    const isLoading = ref(true);

    async function getSuppliers() {
        isLoading.value = true;
        try {
            const query = qs.stringify({
                populate: ['picture'],
                filters: {
                    name: {
                        $containsi: searchQuery.value,
                    },
                },
            });
            const response = (await axios.get(`/vendors?${query}`)) as AxiosResponse<APIResponseMany<'Supplier'>>;
            suppliers.value = response.data.data;
        } catch (error) {
            console.error('Error fetching supplier details:', error);
        } finally {
            isLoading.value = false;
        }
    }

    getSuppliers();

    watch(locale, () => {
        getSuppliers();
    });
</script>

<style>
    @media screen and (min-width: 1024px) {
        .search-input {
            input {
                padding-block: 1.25rem !important;
                padding-left: 2rem !important;
                padding-right: 1rem !important;
            }
        }
    }
</style>